<template>
	<div class="home">
		<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :loading-text="loadingText" :pulling-text="pullingText" :loosing-text="loosingText">
			<van-list v-model="loading" :finished="finished" :finished-text="bomTitle" @load="onLoad" :offset="20" class="main" loading-text="Loading...">
				<div class="newsList" v-for="(item, index) in list" :key="index">
					<div class="newsList_center" v-if="!item.type" @click="goLanding(item.id)">
						<div class="word">
							<div class="title">{{ item.title }}</div>
							<div class="newsInfo">
								<div>
									{{ item.channel }}
								</div>
								<div class="round"></div>
								<div>
									{{
                    $global.formatDate(item.publishedTime, "yyyy-MM-dd hh:mm")
                  }}
								</div>
							</div>
						</div>
						<div class="picurl">
							<img :src="item.headImg" alt="" />
						</div>
					</div>

					<div class="adsItem" v-if="item.type && item.type === -1">
						<ins class="adsbygoogle" style="display: block" :data-ad-client="shell.client" :data-ad-slot="shell.slot1" data-ad-format="auto" data-full-width-responsive="true"></ins>
					</div>
					<div class="adsItem" v-if="item.type && item.type === -2">
						<ins class="adsbygoogle" style="display: block" :data-ad-client="shell.client" :data-ad-slot="shell.slot2" data-ad-format="auto" data-full-width-responsive="true"></ins>
					</div>

					<!-- <section></section> -->
				</div>
			</van-list>
		</van-pull-refresh>
		<!-- <section class="no-data-row" v-if="!isData && requirDataAlready">
      <img src="@/assets/news/nodata.png" alt="" />
      <div class="no-data">
        Uh-oh. What you were looking for got away from you
      </div>
    </section> -->
		<div class="loading" v-if="!requirDataAlready">
			<img src="@/assets/news/loading.png" alt="" />
		</div>
	</div>
</template>
<script>
import "@/css/news/home.scss";
import shell from "@/utlis/shell.js";
export default {
	name: "home",
	data() {
		return {
			list: [],
			hasNext: false,
			isLoading: false, // 控制下拉刷新的加载状态
			loading: false, // 控制上拉加载的加载状态
			finished: false, // 是否已加载完全部数据
			bomTitle: "-- END --", // 上拉加载完成时的文本
			loadingText: "Under loading...", // 加载过程提示文案
			pullingText: "Pull down to refresh...", //下拉过程提示文案
			loosingText: "Release to refresh...", // 释放过程提示文案
			page: 1,
			type: "",
			isinitialization: false,
			isData: false,
			requirDataAlready: true,
			shell,
		};
	},
	mounted() {
		this.type = this.$route.query.type;
		console.log(this.type, "this.type");

		// this.$eventrack("show_h5", 'expose', {
		//   extensions: JSON.stringify({
		//     pve_cur: "/h5page/x/x/",
		//     type: this.type
		//   })
		// });
		this.$eventrackFb("show_page_h5", "expose", {
			pve_cur: "/h5page/x/x/",
			website: this.website,
			type: this.type,
		});
		setTimeout(() => {
			window.addAds();
		}, 1500);
		// this.$nextTick(() => {
		//   window.addAds();
		// })
	},
	methods: {
		getList() {
			return this.$global.server
				.getList({
					page: this.page,
					cid: this.shell.cid,
					sdu: 2,
					size: 30,
				})
				.then((res) => {
					this.page = this.page + 1;
					this.loading = false;
					this.isLoading = false;
					this.finished = !res.data.hasNext;
					if (res.result_code === 200) {
						let data = res.data.source;
						this.list.push(...data);

						if (this.list.length > 3 && !this.list[3].type) {
							this.list.splice(3, 0, {
								type: -1,
							});
						}
						if (this.list.length > 7 && !this.list[7].type) {
							this.list.splice(7, 0, {
								type: -2,
							});
						}
						if (this.list.length > 0) {
							this.isData = true;
						}
						this.hasNext = res.data.hasNext;
					} else {
						this.requirDataAlready = true;
					}
					if (!this.isinitialization) {
						this.isinitialization = true;
						// this.$eventrack("show_h5", 'expose', {
						//   extensions: JSON.stringify({
						//     pve_cur: "/h5page/news/x/",
						//     type: this.type,
						//     state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0
						//   })
						// });
						this.$eventrackFb("show_pageNews_h5", "expose", {
							website: this.website,
							pve_cur: "/h5page/news/x/",
							type: this.type,
							state:
								res.result_code === 200 &&
								res.data.source.length > 1
									? 1
									: 0,
						});
					}
				})
				.catch((err) => {
					this.requirDataAlready = true;
					this.isinitialization = true;
					// this.$eventrack("show_h5", 'expose', {
					//   extensions: JSON.stringify({
					//     pve_cur: "/h5page/news/x/",
					//     type: this.type,
					//     state: -1
					//   })
					// });
					this.$eventrackFb("show_pageNews_h5", "expose", {
						website: this.website,
						pve_cur: "/h5page/news/x/",
						type: this.type,
						state: -1,
					});
				});
		},
		goLanding(id) {
			// this.$eventrack("clicknews_h5", 'click', {
			//   extensions: JSON.stringify({
			//     id: id,
			//   })
			// });
			this.$eventrackFb("clicknews_h5", "click", {
				website: this.website,
				id: id,
			});
			this.$router.push({ name: "landing", query: { id: id } });
		},
		onRefresh() {
			this.finished = false;
			this.loading = true;
			this.page = 1;
			this.onLoad();
		},
		onLoad() {
			this.getList();
			console.log("onLoad");
		},
	},
};
</script>